@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
.padding-container {
    width: 100%;
    padding: 2.5rem;
    background-color: #121212e0;
    display: flex;
    justify-content: space-between;
}

.contact-container {
    width: 100%;
    padding: 2.5rem;
}
.contact-content-container {
    display: flex;
    flex-direction: column;
    gap: 8rem;

    justify-content: center;
    align-items: center;
}

.contact-image-container {
    display: flex;
    flex-direction: column;
    gap: 6rem;
}

.contact-image-row1-container,
.contact-image-row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-image-row1-container,
.contact-image-row2-container {
    gap: 3rem;
}

.contact-image-row1-container{
    margin-bottom: -5%;
    /* margin-top: 5%; */
}

.contact-image-row1-container img {
    width: 40px;
}

.contact-image-row1-container img:hover {
    filter: invert(28%) sepia(84%) saturate(1514%) hue-rotate(189deg)
        brightness(94%) contrast(89%);
}

.contact-image-row2-container h1 {
    color: #37abbc;
    padding-bottom: 1em;
}
.contact-image-row2-container span {
    color: white;
    padding-bottom: 0.2em;
}

.contact-desc-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-desc-address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-title {
    color: #37abbc;
    font-size: 50px;
    font-weight: 900;
    position: relative;
    opacity: 1;
    margin-bottom: -10%;
}

.helmet{
    margin-top: 10%;
    height: 150px;
}


@media only screen and (max-width: 1200px) {
    .contact-image-container {
        margin-top: 20px;
    }
    .cclogo,.helmet{
        display: none;
    }
    .padding-container {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 850px) {
    .contact-image-row2-container {
        display: contents;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .contact-image-row1-container img {
        width: 32px;
    }
    .contact-content-container  {
        gap:4rem;
    }
    .contact-image-row1-container {
        margin-top: -8%;
        gap:1.5rem;
    }
    .section-title {
        color: #37abbc;
        font-size: 32px;
        font-weight: 900;
        position: relative;
        opacity: 1;
        margin-bottom: -10%;
    }
}

